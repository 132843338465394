<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import store from '@/store'

// configs
import {Lang} from '@/configs/index.js'

const props = defineProps({
    secondary: {
        type: Boolean,
        default: false
    },
    isTop: {
        type: Boolean,
        default: false
    }
})

const facebookLink = ref(import.meta.env.VITE_APP_FACEBOOK_LINK_SK)
const linkedInLink = ref(import.meta.env.VITE_APP_LINKEDIN_LINK_SK)

const links = computed(() => {
    return [
        {
            title: 'facebook',
            icon: 'mdi-facebook',
            link: facebookLink.value
        },
        {
            title: 'linkedIn',
            icon: 'mdi-linkedin',
            link: linkedInLink.value
        },
        {
            title: 'instagram',
            icon: 'mdi-instagram',
            link: import.meta.env.VITE_APP_INSTAGRAM_LINK_SK
        },
        {
            title: 'youtube',
            icon: 'mdi-youtube',
            link: import.meta.env.VITE_APP_YOUTUBE_LINK_SK
        }
    ]
})

onBeforeMount(() => {
    switch (store.state.account.locale) {
    case Lang.hr:
        facebookLink.value = import.meta.env.VITE_APP_FACEBOOK_LINK_HR
        linkedInLink.value = import.meta.env.VITE_APP_LINKEDIN_LINK_HR
        break
    case Lang.sk:
        facebookLink.value = import.meta.env.VITE_APP_FACEBOOK_LINK_SK
        linkedInLink.value = import.meta.env.VITE_APP_LINKEDIN_LINK_SK
        break
    default:
        facebookLink.value = import.meta.env.VITE_APP_FACEBOOK_LINK_PL
        linkedInLink.value = import.meta.env.VITE_APP_LINKEDIN_LINK_PL
    }
})
</script>

<template>
    <div class="social-bar">
        <a v-for="{ icon, title, link } in links"
           :key="title"
           :class="{'social-bar__icon': true, 'social-bar__icon--secondary': secondary, 'z-index': isTop }"
           :href="link"
           target="_blank"
        >
            <v-icon>{{ icon }}</v-icon>
        </a>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { dispatch, getters } = store

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// components
import InfoMessage from '@/components/InfoMessage.vue'

// validations
import useValidations from '@/composables/useValidations'
const { isValidYear, isValid2Characters, isLongPlateNumber, maxCharsExceeded, isMaxValue } = useValidations()

const props = defineProps({
    infoMessage: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    isCreate: {
        type: Boolean,
        default: false
    },
    isAddDriverTitle: {
        type: Boolean,
        default: false
    },
    disabledInfoText: {
        type: Boolean,
        default: true
    },
    showClearFilters: {
        type: Boolean,
        default: false
    },
    vehicle: {
        type: [Object, Array],
        default() {
            return {
                id: null,
                producer: null,
                producer_code: null,
                model: null,
                model_code: null,
                version: null,
                version_code: null,
                registration: null,
                production: null,
                plate_number: null,
                note: null
            }
        }
    }
})

const emit = defineEmits(['setCarFields'])

const state = reactive({
    isCarNotInList: false,
	currentYear: new Date().getFullYear()
})

const hasProducerSelected = computed(() => {
    return props.vehicle.producer_code !== null
})

const carBrands = computed(() => {
    if (getters['cars/cars'].length) {
        const arrOfProducers = [...new Set(getters['cars/cars'].map((item) => item.producer))]
        const list = []
        arrOfProducers.forEach((item) => {
            list.push(getters['cars/cars'].find((brand) => brand.producer === item))
        })
        return list
    }
    return []
})

const modelCode = computed(() => {
    const arrayOfModels = getters['cars/cars'].length
        ? getters['cars/cars'].filter((car) => car.producerCode === props.vehicle['producer_code'])
        : []

    // make unique array of models
    return arrayOfModels.filter((a, i) => arrayOfModels.findIndex((s) => a.model === s.model) === i)
})

const versionCode = computed(() => {
    return getters['cars/cars'].length
        ? getters['cars/cars'].filter((car) => car.modelCode === props.vehicle['model_code'])
        : []
})

const registrationYearValid = computed(() => {
    if (props.vehicle['registration'] && props.vehicle.production) {
        return props.vehicle.registration >= props.vehicle.production || t('error.CarRegistrationYear')
    }
    return true
})

const changeProducerCode = (value) => {
    emit('setCarFields', {
        version_code: null,
        model: null,
        model_code: null,
        producer_code: value
    })
}

const changeModelCode = (value) => {
    emit('setCarFields', {
        version_code: null,
        model_code: value
    })
}

const changeVersionCode = (value) => {
    emit('setCarFields', {
        version_code: value
    })
}

const toggleNotInList = (e) => {
    toggleClear()
}

const toggleClear = () => {
    emit('setCarFields', {
        producer: null,
        producer_code: null,
        model: null,
        model_code: null,
        version: null,
        version_code: null,
        note: null
    })
}

const clearAll = () => {
    state.isCarNotInList = false
    emit('setCarFields', {
        producer: null,
        producer_code: null,
        model: null,
        model_code: null,
        version: null,
        version_code: null,
        registration: null,
        production: null,
        plate_number: null,
        note: null
    })
}

onBeforeMount(() => {
    if (state.isCarNotInList) {
        clearAll()
    }
})

onMounted(async () => {
    await dispatch('cars/getCars')
    if (props.vehicle['note']) state.isCarNotInList = true
})
</script>

<template>
    <div class="vehicle-information-wrapper">
        <InfoMessage v-if="props.disabledInfoText" class="my-4">
            {{ props.infoMessage }}
        </InfoMessage>
        <section>
            <h2 class="text-h6 pb-4">
                {{ props.title }}
            </h2>
            <v-container fluid>
                <v-row v-if="props.showClearFilters">
                    <v-col class="text-right" cols="12">
                        <v-btn size="x-small" variant="text" color="primary" @click="clearAll()">
                            {{ t('filter.clear') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0" cols="12" md="4" sm="6">
                        <v-select
                            :disabled="state.isCarNotInList"
                            :items="carBrands"
                            :placeholder="t('addDriver.Producer')"
                            :model-value="vehicle.producer_code"
                            clearable
                            data-testid="carManufacturer"
                            density="compact"
                            item-title="producer"
                            item-value="producerCode"
                            variant="outlined"
                            @update:model-value="changeProducerCode($event)"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12" md="4" sm="6">
                        <v-select
                            :disabled="!hasProducerSelected || state.isCarNotInList"
                            :items="modelCode"
                            :placeholder="t('addDriver.Model')"
                            :model-value="vehicle.model_code"
                            clearable
                            data-testid="carModel"
                            density="compact"
                            item-title="model"
                            item-value="modelCode"
                            variant="outlined"
                            @update:model-value="changeModelCode($event)"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12" md="4" sm="6">
                        <v-select
                            :disabled="!vehicle.model_code || state.isCarNotInList"
                            :items="versionCode"
                            :placeholder="t('addDriver.Version')"
                            :model-value="vehicle.version_code"
                            clearable
                            data-testid="carVersion"
                            density="compact"
                            item-title="version"
                            item-value="versionCode"
                            variant="outlined"
                            @update:model-value="changeVersionCode($event)"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="state.isCarNotInList"
                            :label="t('addDriver.IsNotInTheList')"
                            data-testid="carNotInListCheckbox"
                            @update:model-value="toggleNotInList"
                        />
                        <v-textarea
                            v-show="state.isCarNotInList"
                            :placeholder="t('addDriver.FillProducerModelVersion')"
                            :rules="[isValid2Characters, maxCharsExceeded(120)]"
                            :model-value="vehicle.note"
                            class="pb-4"
                            data-testid="carNotInListText"
                            density="compact"
                            variant="outlined"
                            @update:model-value="emit('setCarFields', { note: $event })"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                <v-row>
                    <v-col class="py-0 mb-4" cols="12" md="4" sm="6">
                        <v-text-field
                            :label="t('addDriver.YearOfRegistration')"
                            :min="1890"
                            :model-value="vehicle.registration"
                            :rules="[isValidYear, registrationYearValid, isMaxValue(state.currentYear, t('error.isMaxValue'))]"
                            class="year-of-registration"
                            data-testid="registrationYear"
                            density="compact"
                            type="number"
                            variant="outlined"
                            @update:model-value="emit('setCarFields', { registration: $event })"
                        />
                    </v-col>
                    <v-col class="py-0 mb-4" cols="12" md="4" sm="6">
                        <v-text-field
                            :label="t('addDriver.YearOfProduction')"
                            :min="1890"
                            :model-value="vehicle.production"
                            :rules="[isValidYear, isMaxValue(state.currentYear, t('error.isMaxValue'))]"
                            class="year-of-production"
                            data-testid="productionYear"
                            density="compact"
                            type="number"
                            variant="outlined"
                            @update:model-value="emit('setCarFields', { production: $event })"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12" md="4" sm="6">
                        <v-text-field
                            :label="t('addDriver.PlateNumber')"
                            :model-value="vehicle.plate_number"
                            :rules="[isValid2Characters, isLongPlateNumber]"
                            class="plate-number"
                            data-testid="licencePlate"
                            density="compact"
                            variant="outlined"
                            @update:model-value="emit('setCarFields', { plate_number: $event })"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </div>
</template>

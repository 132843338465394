<script setup>
import moment from 'moment'
import { useStore } from 'vuex'
import router from '@/router'
import { computed, onBeforeMount, onMounted, onUnmounted, reactive, ref, watch } from 'vue'

// components
import Popup from '@/components/modals/Popup.vue'
import PayerInfoTooltip from './PayerInfoTooltip.vue'

// validations
import useValidations from '@/composables/useValidations'

const { isNotEmpty, isValidPassword, isValidEmail, isValidPhone, isLongPassword, maxCharsExceeded } = useValidations()

// utils
import showMessage from '@/utils/errorMessage'
import { getPopupData } from '@/utils/connectors'
import { formatLanguage } from '@/utils/formatLanguage'
import { getDefaultState } from '@/store/modules/drivers/helper'

// composables
import useBeforeWindowUnload from '@/composables/useBeforeWindowUnload'

// Shared
import Avatar from '../../../components/shared/Avatar.vue'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const beforeWindowUnload = useBeforeWindowUnload()
const formRef = ref()

const store = useStore()
const { dispatch, commit } = store

const emit = defineEmits(['showChargingForm', 'showPrompt'])

const props = defineProps({
    id: {
        type: Number,
        required: true,
        default: () => {
            return null
        }
    }
})

const state = reactive({
    driverDetails: null,
    currency: import.meta.env.VITE_APP_I18N_CURRENCY,
    isPasswordVisible: false,
    isModalStopCharging: false,
    isPopupAskDeleteDriver: false,
    activePopupStopCharging: false,
    enableDriverRemoval: true,
    popupData: {
        question: {
            title: t('driverInfo.AreYouSure'),
            type: 'question',
            subtitle: t('driverInfo.YouWantDeleteDriver'),
            icon: 'mdi-help-circle'
        }
    },
    actionsChange: [
        { title: t('driverInfo.cancel'), action: 'cancel', icon: 'mdi-close', variant: 'flat', color: 'grey' },
        { title: t('driverInfo.apply'), action: 'apply', icon: 'mdi-check', variant: 'flat', color: 'primary' }
    ],
    errors: {
        email: '',
        password: '',
        phone: ''
    }
})

const isLoading = computed(() => store.state.loader)
const driver = computed(() => store.state.drivers.driver)
const primaryDriverId = computed(() => store.state.account.primaryDriverId)
const roles = computed(() => store.state.account.roles)
const actions = computed(() => {
    let actions = [{ title: t('driverInfo.edit'), action: 'edit', icon: 'mdi-pencil' }]
    if (!hideDeleteButton.value) {
        actions.unshift({ title: t('driverInfo.delete'), action: 'delete', icon: 'mdi-delete' })
    }
    return actions
})
const hideDeleteButton = computed(() => {
    return Boolean(props.id === primaryDriverId.value)
})
const isEditView = computed({
    get() {
        return store.state.drivers.isEditView
    },
    set(value) {
        commit('drivers/toggleDriverEdit', value)
    }
})
const driverStatus = computed(() => {
    return t('driverInfo.' + driver.value.status)
})
const formLanguage = computed(() => {
    return formatLanguage(driver.value.lang)
})

const isMainAccount = computed(() => {
    if (isPayerRole.value) {
        return store.state.account.email === state.driverDetails.email
    }
    return false
})
const isPayerRole = computed(() => {
    return store.state.account.roles.includes('payer')
})
const isDriverDeleted = computed(() => {
    return !!driver.value.deletedAt
})

watch(isEditView, (value) => {
    if (value) {
        initDriverDetails()
        window.onbeforeunload = beforeWindowUnload
    } else {
        window.onbeforeunload = null
    }
})

const splitName = (name) => {
    return name.split(' ')
}
const getStopChargingPopupData = () => {
    return getPopupData()
}
const handleAction = (title) => {
    switch (title) {
        case 'delete':
            state.isPopupAskDeleteDriver = !state.isPopupAskDeleteDriver
            break
        case 'edit':
            isEditView.value = !isEditView.value
    }
}
const handleActionDetails = async (title) => {
    const { valid } = await formRef.value.validate()

    switch (title) {
        case 'cancel':
            state.isPasswordVisible = false
            emit('showPrompt')
            break
        case 'apply':
            if (valid) {
                await putDriverDetails()
            } else {
                showMessage(t('error.FormContainsErrors'))
            }
    }
}
const putDriverDetails = async () => {
    await dispatch('drivers/changeDriverDetails', { id: props.id, ...prepareData() })
        .then(() => {
            isEditView.value = !isEditView.value
            dispatch('drivers/getDriver', props.id)
            showMessage(t('driverInfo.DataBeenChangedSuccessfully'), 'success')
        })
        .catch((error) => {
            showMessage(t('error.FormContainsErrors'))
            for (const [key, value] of Object.entries(error)) {
                switch (key) {
                    case 'email':
                        setError('email', value[0])
                        break
                    case 'password':
                        setError('password', value[0])
                        break
                    case 'phone':
                        setError('phone', value[0])
                        break
                    default:
                        showMessage(value[0])
                        break
                }
            }
        })
}
const deleteDriver = async () => {
    dispatch('drivers/deleteDriver', props.id)
        .then((response) => {
            const diff = new Date().getTime() - new Date(response.data.deleted_at).getTime()
            let message
            if (diff > 0) {
                message = t('driverInfo.DriverBeenDeletedSuccessfully')
                router.push('/drivers')
                showMessage(message, 'success')
                return
            }
            message =
                t('driverInfo.DriverWillBeDeleted') + ' ' + moment(response.data.deleted_at).format('DD.MM.YYYY, HH:mm')
            dispatch('drivers/getDriver', props.id)
            showMessage(message, 'success')
        })
        .catch((error) => {
            showMessage(error)
        })
}
const stopCharging = () => {
    try {
        dispatch('connectors/stopCharging', { driver_id: props.id })
            .then(() => {
                showMessage(t('driverInfo.ChargingStoppedSuccessfully'), 'success')
            })
            .catch((error) => {
                showMessage(error)
            })
    } catch (error) {
        showMessage(error.msg)
    }
}
const getHint = () => {
    if (isMainAccount.value) {
        return t('driverInfo.YouCanNotChangeData')
    } else if (!isPayerRole.value) {
        return t('driverInfo.AskYourMainDriver')
    }
    return ''
}
const prepareData = () => {
    const driverDetailsData = state.driverDetails
    const data = {
        email: driverDetailsData.email,
        phone: driverDetailsData.phone,
        first_name: driverDetailsData.first_name,
        language: driverDetailsData.language,
        note: driverDetailsData.note
    }

    if (driverDetailsData.password) {
        data.password = driverDetailsData.password
    }

    return data
}
const setError = (key, value) => {
    state.errors[key] = value
}

const initDriverDetails = () => {
    const driver = { ...store.state.drivers.driver }
    state.driverDetails = {
        email: driver.email,
        phone: driver.phone,
        first_name: driver.name,
        language: driver.lang,
        password: '',
        tariff_id: driver.tariffId,
        note: driver.note,
        isProtected: driver.isProtected
    }
}

onBeforeMount(() => {
    if (!store.state.account.roles.includes('payer')) {
        if (props.id === store.state.account.driver.id) state.enableDriverRemoval = false
    }
})

onMounted(async () => {
    if (isEditView.value) isEditView.value = false
    if (!store.state.filters.languages) await dispatch('filters/getLanguages')

    // TODO - check role first then call tariffs
    if (roles.value.includes('payer') || roles.value.includes('fleet-manager')) {
        await dispatch('tariffs/getTariffs')
    }
})

onUnmounted(() => {
    window.onbeforeunload = null
    state.isEditView = false
    commit('drivers/setDriver', getDefaultState().driver)
})
</script>

<template>
    <section class="driver-info-new">
        <v-row>
            <v-col class="pt-0 mb-4 mt-3 mt-lg-0 d-flex align-center" cols="12">
                <h2 class="home-page-title">
                    {{ t('driverDetails.DriverDetail') }}
                </h2>
                <PayerInfoTooltip v-if="!isPayerRole" />
            </v-col>
        </v-row>
        <v-card :loading="!!isLoading" class="driver-card" elevation="0">
            <v-container class="h-100">
                <v-row
                    v-if="typeof driver !== 'undefined' && typeof driver.name !== 'undefined'"
                    class="driver-wrapper"
                >
                    <v-col
                        class="left-section d-flex align-items-center justify-center text-center my-5"
                        cols="12"
                        lg="5"
                    >
                        <div class="driver-name">
                            <div class="driver-info__avatar pb-3">
                                <Avatar
                                    :first-name="splitName(driver.name)[0]"
                                    :font-size="3"
                                    :last-name="splitName(driver.name)[1]"
                                    :size="135"
                                />
                            </div>
                            <span class="text-caption"> ID: {{ driver.id }} </span>
                            <br />
                            <span class="text-h5 font-weight-medium">
                                {{ driver.name }}
                            </span>
                            <br />
                            <span v-if="driver.note" class="text-caption note">
                                {{ driver.note }}
                            </span>
                            <div v-if="isDriverDeleted">
                                <small>
                                    {{ t('driverInfo.driverWillDeletedAt') }}
                                </small>
                                -
                                <small class="text-no-wrap">
                                    {{ moment(driver.deletedAt).format('DD.MM.YYYY, HH:mm') }}
                                </small>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="isEditView" class="mt-5" cols="12" lg="7">
                        <v-row>
                            <v-col cols="12">
                                <v-form ref="formRef" class="form">
                                    <v-text-field
                                        v-model="state.driverDetails.first_name"
                                        :hint="getHint()"
                                        :label="t('driverInfo.FirstName')"
                                        :placeholder="t('driverInfo.FirstName')"
                                        :readonly="isMainAccount || !isPayerRole"
                                        :rules="[isNotEmpty, maxCharsExceeded(45)]"
                                        class="mt-5"
                                        data-testid="nameEdit"
                                        density="compact"
                                    />
                                    <v-text-field
                                        v-model="state.driverDetails.phone"
                                        :error-messages="state.errors.phone"
                                        :hint="getHint()"
                                        :label="t('driverInfo.Phone')"
                                        :placeholder="t('driverInfo.Phone')"
                                        :readonly="isMainAccount || !isPayerRole"
                                        :rules="[isNotEmpty, isValidPhone]"
                                        class="mt-5"
                                        data-testid="phoneEdit"
                                        density="compact"
                                        @click="state.errors.phone = ''"
                                    />
                                    <v-text-field
                                        v-model="state.driverDetails.email"
                                        :error-messages="state.errors.email"
                                        :hint="getHint()"
                                        :label="t('driverInfo.Email')"
                                        :placeholder="t('driverInfo.Email')"
                                        :readonly="isMainAccount || !isPayerRole"
                                        :rules="[isNotEmpty, isValidEmail, maxCharsExceeded(100)]"
                                        class="mt-5"
                                        data-testid="emailEdit"
                                        density="compact"
                                        @click="state.errors.email = ''"
                                    />
                                    <v-select
                                        v-model="state.driverDetails.language"
                                        :items="store.state.filters.languages || []"
                                        :label="t('driverInfo.CommunicationLanguage')"
                                        :placeholder="t('driverInfo.CommunicationLanguage')"
                                        class="mt-5"
                                        data-testid="languageEdit"
                                        density="compact"
                                        item-title="name"
                                        item-value="value"
                                    />

                                    <template v-if="isPayerRole">
                                        <v-textarea
                                            v-model="state.driverDetails.note"
                                            :label="t('driverInfo.Note')"
                                            :placeholder="t('driverInfo.Note')"
                                            :rules="[maxCharsExceeded(100)]"
                                            data-testid="noteEdit"
                                            variant="outlined"
                                        />
                                    </template>

                                    <template v-else>
                                        <div class="note-info mb-4">
                                            <h6>{{ t('driverInfo.Note') }}</h6>
                                            <span>{{ state.driverDetails.note ? state.driverDetails.note : '-' }}</span>
                                        </div>
                                    </template>
                                    <v-text-field
                                        ref="password"
                                        v-model="state.driverDetails.password"
                                        :append-icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                        :error-messages="state.errors.password"
                                        :label="t('driverInfo.Password')"
                                        :placeholder="t('driverInfo.Password')"
                                        :rules="[isValidPassword, isLongPassword]"
                                        :type="state.isPasswordVisible ? 'text' : 'password'"
                                        autocomplete="new-password"
                                        data-testid="passwordEdit"
                                        density="compact"
                                        variant="outlined"
                                        @click="state.errors.password = ''"
                                        @click:append="state.isPasswordVisible = !state.isPasswordVisible"
                                    />
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col
                                v-for="{ title, action, variant, color } in state.actionsChange"
                                :key="title"
                                class="text-center"
                                cols="6"
                            >
                                <v-btn
                                    :color="color"
                                    :data-testid="`${action}Button`"
                                    :loading="!!isLoading"
                                    :variant="variant"
                                    class="mb-5 text-white"
                                    @click="handleActionDetails(action)"
                                >
                                    {{ title }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-else class="right-section d-flex align-items-center justify-center pl-10" cols="12" lg="7">
                        <v-row>
                            <v-col cols="12" lg="8" order="1" order-lg="0">
                                <v-row>
                                    <v-col class="driver-info__block" cols="12">
                                        <h3>{{ t('driverInfo.Status') }}</h3>
                                        <div data-testid="statusDetail">
                                            {{ driverStatus }}
                                        </div>
                                    </v-col>
                                    <v-col class="driver-info__block" cols="12">
                                        <h3>{{ t('driverInfo.Email') }}</h3>
                                        <div data-testid="emailDetail">
                                            <a :href="`mailto: ${driver.email}`">
                                                {{ driver.email }}
                                            </a>
                                        </div>
                                    </v-col>
                                    <v-col class="driver-info__block" cols="12">
                                        <h3>{{ t('driverInfo.Phone') }}</h3>
                                        <div data-testid="phoneDetail">
                                            <a :href="`tel:${driver.phone}`">
                                                {{ driver.phone }}
                                            </a>
                                        </div>
                                    </v-col>
                                    <v-col class="driver-info__block" cols="12">
                                        <h3>{{ t('driverInfo.Lang') }}</h3>
                                        <div data-testid="languageDetail">
                                            {{ formLanguage }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="text-right pr-6" cols="12" lg="4" order="0" order-lg="1">
                                <template v-if="!isEditView">
                                    <v-tooltip v-for="{ title, action, icon } in actions" :key="title" location="top">
                                        <template v-slot:activator="{ props }">
                                            <template v-if="action === 'delete'">
                                                <v-btn
                                                    v-if="state.enableDriverRemoval"
                                                    :disabled="isDriverDeleted"
                                                    class="driver-info__action-btn px-2 mr-3 min-width-auto"
                                                    color="var(--v-color-gray-darken2)"
                                                    data-testid="deleteDriverButton"
                                                    v-bind="props"
                                                    variant="text"
                                                    @click="handleAction(action)"
                                                >
                                                    <v-icon>{{ icon }}</v-icon>
                                                </v-btn>
                                            </template>

                                            <template v-else>
                                                <v-btn
                                                    :disabled="!!isLoading && driver.id !== null"
                                                    class="text-white"
                                                    color="primary"
                                                    data-testid="editDriverButton"
                                                    @click="handleAction('edit')"
                                                >
                                                    {{ title }}
                                                </v-btn>
                                            </template>
                                        </template>
                                        <span>{{ title }}</span>
                                    </v-tooltip>
                                </template>
                            </v-col>
                        </v-row>
                    </v-col>
                    <Popup
                        :active="state.activePopupStopCharging"
                        :popup-data="getStopChargingPopupData()"
                        @accept="stopCharging()"
                        @cancel="state.activePopupStopCharging = false"
                    />
                    <Popup
                        v-if="state.enableDriverRemoval"
                        :active="state.isPopupAskDeleteDriver"
                        :popup-data="state.popupData.question"
                        @accept="deleteDriver()"
                        @cancel="state.isPopupAskDeleteDriver = false"
                    />
                </v-row>
            </v-container>
        </v-card>
    </section>
</template>

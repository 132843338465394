import axios from 'axios'
import moment from 'moment'
import { store } from '@/store'
import { useCookies } from 'vue3-cookies'
import { getParsedJwt } from './jwtParse'

// utils
import { setCookieDomain } from '@/utils/setCookieDomain'

// configs
import { Lang, Locale } from '@/configs/index.js'

const { CancelToken } = axios
const source = CancelToken.source()

const getLanguage = () => {
    const { cookies } = useCookies()

    if (window.location.href.indexOf('/en/') > -1 || window.location.href.indexOf('/EN/') > -1) {
        return Locale.en.toUpperCase()
    }
    if (window.location.href.indexOf('/sk/') > -1 || window.location.href.indexOf('/SK/') > -1) {
        return Locale.sk.toUpperCase()
    }
    if (window.location.href.indexOf('/hr/') > -1 || window.location.href.indexOf('/HR/') > -1) {
        return Locale.sk.toUpperCase()
    }

    const cookieLang = cookies.get('gw_lang') || null
    const language = cookieLang ? cookieLang : import.meta.env.VITE_APP_I18N_LOCALE || import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || Lang.en

    return language.toLowerCase()
}

function deleteAllCookies() {
    const cookies = document.cookie.split(";")

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf("=")
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
    }
}

async function getError(err) {

    if (err.response === undefined) return Promise.reject(err)

    const { status } = err.response

    switch (status) {
        case 401:
            if (err.response.config.url.includes('gitlab')) {
                return Promise.reject(err)
            }
            await store?.dispatch('account/logout', false)

            // Clean cookies so there is no circular redirect
            if (store === null && typeof t === 'undefined') {
                deleteAllCookies()
            }

            window.location = '/login'
            break
        case 500:
            store?.commit('setNotify', {
                content: store?.state?.translation[store?.state?.account.lang]['error.500'],
                color: 'error'
            }, { root: true })
            break
        case 404:
            store?.commit('setNotify', {
                content: store?.state?.translation[store?.state?.account.lang]['error.404'] || 'Page not found!',
                color: 'error'
            }, { root: true })
            return Promise.reject(err)
        case 419:
            return Promise.reject(err)
        case 503:
            return Promise.reject(err)
    }
    if (err.response?.data) {
        return Promise.reject(err.response.data)
    } else if (err.response?.data?.message) {
        return Promise.reject(err.response.data.message)
    }
    return Promise.reject(err)
}

let urlParams = new URLSearchParams(window.location.search)
if (urlParams.has('bearer')) {
    const token = urlParams.get('bearer')
    const parsedToken = getParsedJwt(token)
    const expireAt = moment(new Date(1970, 0, 1).setSeconds(parsedToken.exp)).format()

    document.cookie = `gw_token=${token};path='/';domain=${setCookieDomain()}`
    document.cookie = `gw_token_expires_at=${expireAt};path='/';domain=${setCookieDomain()}`
    document.cookie = `token_urlBearer=${true};path='/';domain=${setCookieDomain()}`

    // cleanup
    const url = new URL(window.location)
    url.searchParams.delete('bearer')
    window.history.pushState({}, '', url)
}

function getAuthorization() {
    const { cookies } = useCookies()
    return `Bearer ${cookies.get('gw_token')}`
}

function handleBlobRequestType(error) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = async () => {
            error.response.data = JSON.parse(reader.result)
            resolve(Promise.reject(error))
        }
        reader.onerror = () => {
            reject(error)
        }
        reader.readAsText(error.response.data)
    })
}

const apiRequest = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    headers: {
        'Authorization': getAuthorization(),
        'Accept': 'application/json',
        'Accept-Language': getLanguage()
    },
    cancelToken: source.token
})

apiRequest.interceptors.request.use(
    config => {
        config.headers['Accept-Language'] = getLanguage()
        config.headers['Authorization'] = getAuthorization()
        return config
    }, async error => {
        getError(error)
    }
)

apiRequest.interceptors.response.use(
    response => {
        if (response.headers['x-version-app']) {
            if (store?.state.version) {
                if (store?.state.version !== response.headers['x-version-app']) {
                    store?.commit('setVersion', response.headers['x-version-app'], { root: true })
                    window.location.reload()
                }
            } else {
                store?.commit('setVersion', response.headers['x-version-app'], { root: true })
            }
        }
        return Promise.resolve(response)
    },
    async error => {
        if (axios.isCancel(error)) {
            console.warn('Request canceled', error.message)
            return Promise.reject(error)
        }
        if (error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1) {
            await handleBlobRequestType(error)
        }
        await getError(error)
    }
)

const apiNoAuth = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        'Accept-Language': getLanguage()
    }
})

export { apiRequest, apiNoAuth }
<script setup>
import moment from 'moment'

// utils
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const EXPIRE_FORMAT = 'MM/YY'

const emit = defineEmits(['showPopup'])
const props = defineProps({
    paymentInfo: {
        type: Object,
        required: true
    }
})

const popup = () => {
    emit('showPopup')
}

const isMasterCard = (value) => {
	return value.toLowerCase().includes('master')
}

const parseCardNumber = (value) => {
	return isMasterCard(value) ? value : value.replace(/.{4}/g, ' $&')
}
</script>

<template>
    <v-card
        class="payment-card-mock pa-4 mx-auto"
        max-width="350"
    >
        <v-tooltip top>
            <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    variant="flat"
                    size="medium"
                    class="card-remove"
                    data-testid="remove-card"
                    @click="popup()"
                >
                    <v-icon color="var(--v-error)">
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ t('account.removePaymentCard') }}</span>
        </v-tooltip>
        <div class="mt-14 mb-2" >
            <span class="text-caption">{{ t('managePayment.cardNumber') }}</span>
            <h5 class="font-weight-medium"
                :class="isMasterCard(props.paymentInfo.number) ? 'text-h6' : 'text-h5'"
            >
                {{ parseCardNumber(props.paymentInfo.number) }}
            </h5>
        </div>
        <div class="card-expire text-right">
            <span class="text-caption">{{ t('account.expires') }}</span>
            <h3 class="font-weight-medium">
                {{ moment(props.paymentInfo.expire).format(EXPIRE_FORMAT) }}
            </h3>
        </div>
    </v-card>
</template>

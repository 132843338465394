<script setup>
import moment from 'moment'
import { useStore } from 'vuex'
import { computed, watch, reactive, onMounted } from 'vue'

// composables
import useWindowWidth from '@/composables/windowWidth'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'

// configs
import { DATE_FORMAT } from '@/configs'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { windowWidth } = useWindowWidth()
const store = useStore()
const { getters, dispatch } = store

const emit = defineEmits(['refetch', 'cancel'])

const state = reactive({
    loading: false,
    tariffPrice: null,
    isCurrentTariffPayed: false
})

const props = defineProps({
    tempPriceChange: {
        type: Object,
        default: null
    },
    driverId: {
        type: Number,
        default: null
    }
})

const tariffs = computed(() => {
    return store.state.tariffs.tariffs
})

const getTariff = (tariffID) => {
    return tariffs.value.find((el) => el.id === tariffID) || null
}

const locale = computed(() => {
    return getters['account/locale']
})

const getCurrency = computed(() => {
    return (
        import.meta.env[`VITE_APP_I18N_CURRENCY_${locale.value.toUpperCase()}`] ||
        import.meta.env.VITE_APP_I18N_CURRENCY
    )
})

const changePriceProgram = async () => {
    state.loading = true
    await dispatch('drivers/changeDriverTariff', {
        tariff_id: props.tempPriceChange.tariffId,
        driver_id: props.driverId
    })
        .then((response) => {
            if (response.error) showMessage(response.error)
            if (response.data.message) showMessage(response.data.message, 'success')
        })
        .catch((error) => {
            showMessageArray(error)
        })
        .finally(() => {
            emit('cancel')
            emit('refetch')
            state.loading = false
        })
}

const cancel = () => {
    emit('cancel')
}

const getTariffPrice = () => {
    state.loading = true
    dispatch('tariffs/getTariffPrice', {
        id: props.tempPriceChange?.tariffId,
        driver_id: props.driverId
    })
        .then((res) => {
            state.tariffPrice = res
        })
        .finally(() => {
            state.loading = false
        })
}

watch(
    () => props.tempPriceChange,
    (value) => {
        if (value) {
            state.loading = true
            if (value.tariffId) getTariffPrice()
        }
    },
    { deep: true }
)

onMounted(() => {
    if (getters['drivers/driver'].tariff.price_list && getters['drivers/driver'].tariff.price_list.monthlyPrice > 0) {
        state.isCurrentTariffPayed = true
        return
    }
    getTariffPrice()
})
</script>

<template>
    <v-row>
        <v-col class="text-center" cols="12">
            <h3>
                {{ t('driverInfo.changeOfPriceProgram') }}
            </h3>
        </v-col>
        <v-col class="text-center" cols="12">
            <span>
                {{ t('registration.summary') }}
            </span>
        </v-col>
        <v-col
            v-if="!state.isCurrentTariffPayed && !state.tariffPrice"
            class="d-flex align-items-center text-center justify-center"
            cols="12"
        >
            <v-progress-circular :size="50" class="m-auto" color="primary" indeterminate />
        </v-col>
        <v-col v-else class="price-box-wrapper pa-5" cols="12" lg="4" offset-lg="4">
            <template v-if="state.isCurrentTariffPayed">
                {{ t('driverInfo.tariffActiveNextMonth', { tariff: getTariff(props.tempPriceChange.tariffId).name }) }}
            </template>
            <template v-else>
                <v-row class="price-box border-bottom">
                    <v-col class="border-bottom" cols="12" lg="6">
                        <span class="price-program-info">
                            {{
                                state.loading
                                    ? '-'
                                    : state.tariffPrice
                                      ? state.tariffPrice.name
                                      : getTariff(props.tempPriceChange.tariffId).name || '-'
                            }}
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-lg-end border-bottom" cols="12" lg="6">
                        <small v-if="state.tariffPrice?.valid_from">
                            {{ t('driverInfo.validFrom') }}:
                            {{ moment(state.tariffPrice.valid_from).format(DATE_FORMAT) }}
                        </small>
                    </v-col>
                    <v-col cols="6" md="8">
                        <small>
                            {{ t('registration.monthlyUntil') }}
                        </small>
                    </v-col>
                    <v-col cols="6" md="4">
                        <h3 class="price text-right">
                            {{ state.loading ? '-' : state.tariffPrice ? state.tariffPrice.price_aliquot : '?' }}
                            {{ getCurrency }}
                        </h3>
                    </v-col>
                </v-row>
                <v-row class="price-box">
                    <v-col cols="6" md="8">
                        {{ t('registration.sum') }}
                    </v-col>
                    <v-col cols="6" md="4">
                        <h3 class="price text-right">
                            {{ state.loading ? '-' : state.tariffPrice ? state.tariffPrice.price_aliquot : '?' }}
                            {{ getCurrency }}
                        </h3>
                    </v-col>
                </v-row>
            </template>
        </v-col>
        <v-col class="text-center mt-5" cols="12">
            <span>
                {{ t('registration.followingPayments') }}
            </span>
        </v-col>
        <v-col class="price-box-wrapper outlined pa-5" cols="12" md="4" offset-md="4">
            <v-row class="price-box">
                <v-col cols="6" md="8">
                    <span class="price-program-info">
                        {{ getTariff(tempPriceChange.tariffId).name }}
                    </span>
                    <br />
                    <small>
                        {{ t('registration.monthlyFee') }}
                    </small>
                </v-col>
                <v-col cols="6" md="4">
                    <h3 class="price text-right">
                        {{ tempPriceChange.price ? tempPriceChange.price.toFixed(2) : 0 }} {{ getCurrency }}
                    </h3>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="d-flex justify-space-between px-0" cols="12" lg="4" offset-lg="4">
            <v-row>
                <v-col cols="12" lg="6">
                    <Btn
                        :block="windowWidth < 1280 ? true : false"
                        :disabled="state.loading"
                        :loading="state.loading"
                        class="modal__btn active-button active-link mr-1"
                        medium
                        text
                        variant="outlined"
                        @click="cancel"
                    >
                        {{ t('oAuth.cancel') }}
                    </Btn>
                </v-col>
                <v-col class="text-right" cols="12" lg="6">
                    <Btn
                        :block="windowWidth < 1280 ? true : false"
                        :disabled="state.loading"
                        :loading="state.loading"
                        class="modal__btn ml-1"
                        medium
                        @click="changePriceProgram"
                    >
                        {{ t('driverInfo.confirmChange') }}
                    </Btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

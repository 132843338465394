<script setup>
import router from '@/router'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue'

// components
import Btn from '@/components/inputs/Btn.vue'
import Pricing from '@/components/Pricing.vue'
import Modal from '@/components/modals/Modal.vue'
import Popup from '@/components/modals/Popup.vue'
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'
import RfidCardRequest from '@/components/RfidCardRequest.vue'
import VehicleInformation from '@/components/VehicleInformation.vue'

// utils
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import { getEshopProducts, orderCard } from '@/utils/eshop'

// Composables
import initializeLinks from '@/composables/useSidebarMenuLinks'

// validations
import useValidations from '@/composables/useValidations'

const {
    isNotEmpty,
    isValid2Characters,
    isValidEmail,
    isValidPassword,
    isLongPassword,
    isValidPhone,
    maxCharsExceeded
} = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const formRef = ref()

const store = useStore()
const { dispatch } = store

const state = reactive({
    isTariffsWithTable: false,
    isFormValid: false,
    isPasswordVisible: false,
    isPopupAskSave: false,
    emailErrorMessage: '',
    passwordErrorMessage: '',
    formErrorMessage: '',
    showDialogBox: false,
    navigateTo: '',
    isInEditMode: false,
    fetchingTariffs: false,
    addDriver: {
        tariff_id: 0,
        tariff_price: 0,
        password: '',
        language: store.state.account.lang,
        first_name: '',
        email: '',
        phone: '',
        vehicle: {
            id: null,
            producer: null,
            producer_code: null,
            model: null,
            model_code: null,
            version: null,
            version_code: null,
            registration: null,
            production: null,
            plate_number: null,
            note: null
        }
    },
    popupQuestion: {
        title: t('driverInfo.AreYouSure'),
        type: 'question',
        subtitle: t('driverInfo.DoYouWantToCreateDriver'),
        icon: 'mdi-help-circle'
    },
    errors: {
        first_name: '',
        email: '',
        password: '',
        phone: ''
    },
    vehicleInformation: {
        title: t('vehicleInformation.title'),
        infoMessage: t('vehicleInformation.infoMessage'),
        isAddDriverTitle: true
    },
    primaryDriver: null
})

const requestCard = computed(() => store.state.eshop.requestCard)
const eshopProducts = computed(() => store.state.eshop.products)

const requestCardHasError = computed({
    get() {
        return store.state.eshop.requestCardHasError
    },
    set(value) {
        store.commit('eshop/setFieldValue', {
            field: 'requestCardHasError',
            value
        })
    }
})

const tariffs = computed({
    get() {
        return store.state.tariffs.tariffs
    },
    set(tariffs) {
        store.commit('tariffs/updateFieldValue', {
            field: 'tariffs',
            value: tariffs
        })
    }
})

const getProducts = (driver_id) => {
    return getEshopProducts(driver_id)
}

const getTariffs = async () => {
    let trfs = []
    try {
        state.fetchingTariffs = true
        const { data } = await apiRequest('tariffs')
        data.forEach((tariff) =>
            trfs.push({
                id: tariff.id,
                name: tariff.name,
                monthlyPrice: tariff['monthly_price'],
                priceList: tariff['price_list']
            })
        )
        tariffs.value = trfs
    } catch (e) {
        return []
    } finally {
        state.fetchingTariffs = false
    }
}

const handleSave = () => {
    state.isPopupAskSave = !state.isPopupAskSave
}

const getPrimaryDriverTariffId = async () => {
    try {
        const { data } = await apiRequest(`client/drivers/${store.state.account.primaryDriverId}`)
        state.primaryDriver = data
    } catch (e) {
        showMessage(e.message)
    }
}

const getTariff = () => {
    if (state.primaryDriver) {
        switch (state.primaryDriver.tariff.is_public) {
            case 0:
                return null
            case 1:
            default:
                return tariffs.value.length ? state.addDriver.tariff_id : null
        }
    }
    return null
}

const save = async () => {
    const { valid } = await formRef.value.validate()

    if (!state.primaryDriver) {
        showMessage(t('drivers.contactOperator'))
        return
    }

    requestCard.value === null ? (requestCardHasError.value = true) : (requestCardHasError.value = false)
    if (requestCard.value === null) {
        showMessage(t('error.FormContainsErrors'))
        return
    }

    if (valid) {
        state.addDriver.tariff_id = getTariff()
        dispatch('drivers/addDriver', state.addDriver)
            .then(async (data) => {
                showMessage(t('driverInfo.DriverHasBeenCreated'), 'success')
                if (requestCard.value) {
                    getProducts(data.id).then(async () => {
                        // check if RFID card in eshop exists first
                        const rfid = eshopProducts.value.find((el) => el.code.trim().toLowerCase() === 'rfid') || null
                        if (rfid) {
                            const driver_id = data.id
                            const items = [
                                {
                                    product_id: rfid.id,
                                    quantity: 1
                                }
                            ]
                            await orderCard(rfid, driver_id, items)
                        } else {
                            showMessage(t('orderCard.unableToOrder'))
                        }
                    })
                }

                //  we need to reinitialize account data since we can have changed number of drivers
                // which impacts drivers section visibility
                dispatch('account/getAccount')
                    .then(() => {
                        initializeLinks()
                        router.push('/drivers')
                    })
                    .catch(() => {
                        router.push('/drivers')
                    })
            })
            .catch((errors) => {
                if (errors) {
                    for (const [key, value] of Object.entries(errors)) {
                        switch (key) {
                            case 'email':
                                errors.email = value[0]
                                showMessage(value[0])
                                break
                            case 'password':
                                errors.password = value[0]
                                showMessage(value[0])
                                break
                            case 'vehicle':
                                showMessage(value[0])
                                break
	                        default:
								errors[key] = value[0]
								showMessage(value[0])
                        }
                    }
                } else {
                    showMessage(errors)
                }
            })
    } else {
        showMessage(t('error.FormContainsErrors'))
    }
}

const setTariff = (data) => {
    state.addDriver.tariff_id = tariffs.value.length ? data.id : state.primaryDriver.tariff_id
    state.addDriver.tariff_price = data.price ?? 0
}

const setCarFields = (data) => {
	state.addDriver.vehicle = {...state.addDriver.vehicle, ...data}
}

const cancel = () => {
    state.navigateTo = ''
    router.push({ path: '/drivers' })
}

const resolveYes = () => {
    state.showDialogBox = false
    if (state.navigateTo !== '') {
        state.isInEditMode = false
        router.push({ path: state.navigateTo })
        state.navigateTo = ''
        return
    }
    router.push({ path: '/drivers' })
}

onBeforeMount(() => {
    state.isInEditMode = true
})

onMounted(async () => {
    await getPrimaryDriverTariffId()
    await getTariffs()
    if (!store.state.filters.languages) dispatch('filters/getLanguages')
    await dispatch('drivers/getDrivers')
})

onBeforeRouteLeave(async (to) => {
    const { valid } = await formRef.value.validate()
    if (state.isInEditMode && !valid) {
        state.showDialogBox = true
        state.navigateTo = to.path
        return false
    }
})
</script>

<template>
    <div class="driver add-driver-wrapper">
        <BtnGoBack class="ml-6 pt-2" />
        <header class="driver__header pa-6">
            <h1 class="home-page-title driver__header--title">
                {{ t('addDriver.AddDriver') }}
            </h1>
        </header>
        <main class="driver__main px-2 px-md-6">
            <template v-if="state.fetchingTariffs">
                <div class="d-flex flex-column flex-md-row justify-center align-center mb-6">
                    <v-skeleton-loader type="card" width="300px" height="570px"></v-skeleton-loader>
                    <v-skeleton-loader
                        type="card"
                        width="300px"
                        height="570px"
                        class="my-4 my-md-0 mx-md-4"
                    ></v-skeleton-loader>
                    <v-skeleton-loader type="card" width="300px" height="570px"></v-skeleton-loader>
                </div>
                <div>
                    <v-skeleton-loader type="article" class="mb-4"></v-skeleton-loader>
                </div>
            </template>
            <template v-else>
                <Pricing
                    v-if="state.primaryDriver && state.primaryDriver.tariff.is_public && tariffs.length"
                    v-model="state.addDriver.tariff_id"
                    :is-add-driver="true"
                    :is-hide-details="false"
                    :tariff-id="state.addDriver.tariff_id"
                    @setTariff="setTariff"
                />
                <v-card
                    v-if="state.primaryDriver && !state.primaryDriver.tariff.is_public"
                    max-width="320px"
                    class="mb-4 bg-primary"
                >
                    <v-card-title class="text-white">{{ t('driverInfo.PriceProgram') }}</v-card-title>
                    <v-card-text class="text-black font-weight-bold">{{ state.primaryDriver.tariff_name }}</v-card-text>
                </v-card>
            </template>
	        <v-form ref="formRef" v-model="state.isFormValid" class="form">
	            <section class="section">
	                <h2 class="text-h6 pb-4">
	                    {{ t('addDriver.DriverData') }}
	                </h2>
	                <v-container fluid>
	                        <v-row>
	                            <v-col class="py-0" cols="12" md="4" sm="6">
	                                <v-text-field
	                                    v-model="state.addDriver.first_name"
	                                    :error-messages="state.errors.first_name"
	                                    :label="`${t('addDriver.FirstName')}*`"
	                                    :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
	                                    data-testid="firstName"
	                                    density="compact"
	                                    variant="outlined"
	                                    @click="state.errors.first_name = ''"
	                                />
	                            </v-col>
	                            <v-col class="py-0" cols="12" md="4" sm="6">
	                                <v-text-field
	                                    v-model="state.addDriver.phone"
	                                    :error-messages="state.errors.phone"
	                                    :label="`${t('addDriver.Phone')}*`"
	                                    :rules="[isNotEmpty, isValidPhone]"
	                                    data-testid="phone"
	                                    density="compact"
	                                    variant="outlined"
	                                    @click="state.errors.phone = ''"
	                                />
	                            </v-col>
	                            <v-col class="py-0" cols="12" md="4" sm="6">
	                                <v-text-field
	                                    v-model="state.addDriver.email"
	                                    :label="`${t('addDriver.Email')}*`"
	                                    :rules="[isNotEmpty, isValidEmail, maxCharsExceeded(100)]"
	                                    data-testid="email"
	                                    density="compact"
	                                    variant="outlined"
	                                    @click="state.errors.email = ''"
	                                />
	                            </v-col>
	                            <v-col class="py-0" cols="12" md="4" sm="6">
	                                <v-text-field
	                                    v-model="state.addDriver.password"
	                                    :label="t('driverInfo.Password') + '*'"
	                                    :append-inner-icon="state.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
	                                    :rules="[isNotEmpty, isValidPassword, isLongPassword]"
	                                    :type="state.isPasswordVisible ? 'text' : 'password'"
	                                    data-testid="password"
	                                    density="compact"
	                                    variant="outlined"
	                                    @click="state.errors.password = ''"
	                                    @click:append-inner="state.isPasswordVisible = !state.isPasswordVisible"
	                                    autocomplete="new-password"
	                                >
	                                </v-text-field>
	                            </v-col>
	                            <v-col class="py-0" cols="12" md="4" sm="6">
	                                <v-select
	                                    v-model="state.addDriver.language"
	                                    :items="store.state.filters.languages || []"
	                                    :placeholder="`${t('addDriver.CommunicationLanguage')}*`"
	                                    :rules="[state.isNotEmpty]"
	                                    item-title="name"
	                                    item-value="value"
	                                    density="compact"
	                                    variant="outlined"
	                                    data-testid="language"
	                                />
	                            </v-col>
	                        </v-row>
	                </v-container>
	            </section>
	            <section class="section py-1 pt-5">
	                <RfidCardRequest />
	            </section>
	            <VehicleInformation
	                :info-message="state.vehicleInformation.infoMessage"
	                :is-add-driver-title="state.vehicleInformation.isAddDriverTitle"
	                :is-create="true"
	                :title="state.vehicleInformation.title"
	                :vehicle="state.addDriver.vehicle"
	                @setCarFields="setCarFields"
	            />
	        </v-form>
            <div class="py-6 d-flex justify-center">
                <Btn
                    :disabled="store.getters['isLoading']"
                    class="mr-6"
                    color="primary"
                    data-testid="cancelForm"
                    variant="outlined"
                    @click="cancel"
                >
                    {{ t('addDriver.cancel') }}
                </Btn>
                <Btn
                    v-if="state.addDriver.tariff_price === 0"
                    :loading="store.getters['isLoading']"
                    data-testid="saveForm"
                    @click="save"
                >
                    {{ t('addDriver.save') }}
                </Btn>
                <Btn v-else :loading="store.getters['isLoading']" data-testid="saveForm" @click="handleSave">
                    {{ t('addDriver.save') }}
                </Btn>
            </div>
        </main>
        <!-- has changes dialog box -->
        <Modal :is-active="state.showDialogBox" :max-width="275">
            <div class="modal">
                <div class="pa-5">
                    <h4 class="py-5 mb-5 text-center">
                        {{ t('error.HasChanges') }}
                    </h4>
                    <div class="d-flex justify-space-around">
                        <Btn class="modal__btn" medium @click="state.showDialogBox = false">
                            {{ t('oAuth.cancel') }}
                        </Btn>
                        <Btn class="modal__btn active-link active-button" medium text @click="resolveYes">
                            {{ t('BtnGoBack.ok') }}
                        </Btn>
                    </div>
                </div>
            </div>
        </Modal>
        <Popup
            :active="state.isPopupAskSave"
            :loading="store.getters['isLoading']"
            :popup-data="state.popupQuestion"
            @accept="save()"
            @cancel="state.isPopupAskSave = false"
        />
    </div>
</template>

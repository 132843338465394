<script setup>
/**
 * Vehicle Details
 * @vuedoc
 * @exports VehicleDetails
 * @namespace Drivers.VehicleDetails
 */

import {computed, onMounted, reactive, ref} from 'vue'
import {useStore} from 'vuex'

// utils
import showMessage from '@/utils/errorMessage'
import isEmptyObj from '@/utils/isEmptyObj'

// validations
import useValidations from '@/composables/useValidations'

const {isNotEmpty} = useValidations()

// helpers
import useWindowWidth from '@/composables/windowWidth'

// components
import Btn from '@/components/inputs/Btn.vue'
import Popup from '@/components/modals/Popup.vue'
import Modal from '@/components/modals/Modal.vue'
import VehicleInformation from '@/components/VehicleInformation.vue'

// translations
import {useI18n} from 'vue-i18n'
import errorMessageArray from "@/utils/errorMessageArray.js";

const {t} = useI18n()

const store = useStore()
const {dispatch} = store

const props = defineProps({
	driver: {
		required: true,
		type: Object,
		default: () => {
			return null
		}
	}
})


const vehicleFormAdd = ref(false)
const vehicleFormEdit = ref(true)
const formRef = ref()

const {
	windowWidth,
	setWindowWidth
} = useWindowWidth()

const state = reactive({
	isEmptyObj,
	addVehicleFields: null,
	editVehicleFields: null,
	isPopupAddVehicle: false,
	isPopupEditVehicle: false,
	editVehicleFieldsBefore: null,
	isMobileView: false,
	isEditView: false,
	isPopupAskDeleteVehicle: false,
	actions: [
		{title: 'delete', icon: 'mdi-delete', action: 'openDeletePopup'},
		{title: 'edit', icon: 'mdi-pencil', action: 'openEditPopup'}
	],
	popupData: {
		question: {
			title: t('driverInfo.AreYouSure'),
			subtitle: t('driverInfo.YouWantDeleteVehicle'),
			icon: 'mdi-help-circle'
		}

	},
	isNotEmpty
})

const isLoading = computed(() => store.state.loader)
const vehicle = computed(() => store.state.drivers.driver.vehicle)
const cars = computed(() => store.state.cars.cars)
const isDriverRole = computed(() => store.state.account.roles.includes('driver'))
const isDriverDeleted = computed(() => !!props.driver?.deletedAt)
const isAddVehicleSaveEnabled = computed(() => {
	let data = []
	if (state.addVehicleFields) {
		Object.keys(state.addVehicleFields).forEach(key => {
			if (state.addVehicleFields[key] && state.addVehicleFields[key].toString().trim().length) {
				data.push(true)
			}
		})
	}
	return data.some(el => el)
})
const isEditVehicleSaveEnabled = computed(() => {
	let data = []
	if (JSON.stringify(state.editVehicleFields) !== JSON.stringify(state.editVehicleFieldsBefore)) {
		const toCheck = ['producer_code', 'model_code', 'version_code', 'note', 'registration', 'production', 'plate_number']
		toCheck.forEach(key => {
			if (state.editVehicleFields[key] && state.editVehicleFields[key].toString().trim().length) {
				data.push(true)
			}
		})
	}
	return data.some(el => el)
})
const headers = computed(() => {
	const columns = [
		{
			title: t('addVehicle.Producer'),
			align: 'start',
			sortable: false,
			value: 'producer'
		},
		{
			title: t('addVehicle.Model'),
			align: 'start',
			sortable: false,
			value: 'model'
		},
		{
			title: t('addVehicle.Version'),
			align: 'start',
			sortable: false,
			value: 'version'
		},
		{
			title: t('addVehicle.Other'),
			align: 'start',
			sortable: false,
			value: 'note'
		},
		{
			title: t('addVehicle.Registration'),
			align: 'start',
			sortable: false,
			value: 'registration'
		},
		{
			title: t('addVehicle.Production'),
			align: 'start',
			sortable: false,
			value: 'production'
		},
		{
			title: t('addVehicle.PlateNo'),
			align: 'start',
			sortable: false,
			value: 'plate_number'
		},
		{
			title: t('tableHeaders.Actions'),
			align: 'start',
			sortable: false,
			value: 'actions'
		}
	]
	if (windowWidth < 600) {
		columns.unshift({value: 'actionsMobile'})
		columns.pop()
	}
	return columns
})

const saveCarsData = async () => {
	const {valid} = await formRef.value.validate()
	if (!valid) {
		showMessage(t('error.FormContainsErrors'))
		return
	}
	dispatch('drivers/addVehicle', {id: props.driver.id, car: state.addVehicleFields})
		.catch(errors => {
			errorMessageArray(errors, 'error')
		}).finally(() => {
		closeAddVehiclePopup()
	})
}
const confirmEdit = async () => {
	const {valid} = await formRef.value.validate()
	if (!valid) {
		showMessage(t('error.FormContainsErrors'))
		return
	}

	dispatch('drivers/putVehicleDetails', {id: props.driver.id, car: state.editVehicleFields})
		.catch(error => {
			showMessage(error)
		}).finally(() => {
		closeEditPopup()
	})
}
const resetEditCarFields = () => {
	state.editVehicleFieldsBefore = {...vehicle.value}
	state.editVehicleFields = {...vehicle.value}
}
const closeEditPopup = () => {
	resetEditCarFields()
	state.isPopupEditVehicle = false
	state.vehicleFormEdit = true
}
const setEditCarFields = (data) => {
	Object.assign(state.editVehicleFields, data)
}
const setCarFields = (data) => {
	state.addVehicleFields = {...state.addVehicleFields, ...data}
}
const resetCarFields = () => {
	state.addVehicleFields = {...props.driver?.vehicle}
}
const openAddVehiclePopup = () => {
	resetCarFields()
	state.isPopupAddVehicle = true
}
const closeAddVehiclePopup = () => {
	resetCarFields()
	state.isPopupAddVehicle = false
}
const openEditPopup = () => {
	resetEditCarFields()
	state.isPopupEditVehicle = true
}
const openDeletePopup = () => {
	state.isPopupAskDeleteVehicle = true
}
const resolveAction = (val) => {
	switch (val) {
	case 'openDeletePopup':
		openDeletePopup()
		break
	default:
		openEditPopup()
	}
}
const deleteVehicle = () => {
	dispatch('drivers/deleteVehicle', props.driver.id).then(() => {
		showMessage(t('driverInfo.VehicleBeenDeletedSuccessfully'), 'success')
	}).catch(error => {
		showMessage(error)
	})
}

onMounted(async () => {
	await dispatch('cars/getCars')
})

</script>

<template>
	<div class="vehicle-details-wrapper">
		<header class="section__header d-flex flex-column flex-sm-row justify-space-between my-3">
			<h2 class="home-page-title">
				{{ t('driverDetails.VehicleDetail') }}
			</h2>
			<template v-if="!!!isLoading">
				<div class="buttons-wrapper d-flex my-4 my-sm-0 justify-center">
					<Btn v-if="isEmptyObj(driver.vehicle) && isDriverRole"
					     :disabled="!!isLoading || !!driver.deletedAt"
					     @click="openAddVehiclePopup"
					>
						{{ t('driverDetails.addVehicle') }}
					</Btn>
				</div>
			</template>
		</header>

		<template v-if="isLoading || !driver">
			<v-row class="price-list-box ma-4 mb-5 text-center">
				<v-col cols="12">
					<v-progress-circular
						:size="50"
						class="m-auto"
						color="primary"
						indeterminate
					/>
				</v-col>
			</v-row>
		</template>

		<template v-else>
			<v-data-table
				v-if="!isEmptyObj(vehicle)"
				:headers="headers"
				:items="[vehicle]"
			>
				<template #bottom></template>
				<template v-if="isDriverRole" v-slot:[`item.actions`]>
					<div>
						<v-btn v-for="{ title, icon, action } in state.actions"
						       :key="title"
						       :disabled="isDriverDeleted"
						       class="px-2 actions__button min-width-auto"
						       color="'var(--v-color-gray-darken2)'"
						       variant="plain"
						       @click="resolveAction(action)"
						>
							<v-icon>{{ icon }}</v-icon>
						</v-btn>
					</div>
				</template>
				<template v-if="isDriverRole" v-slot:[`item.actionsMobile`]>
					<div>
						<v-btn v-for="{ title, icon, action } in state.actions"
						       :key="title"
						       :color="'var(--v-color-gray-darken2)'"
						       :disabled="isDriverDeleted"
						       class="px-2 actions__button min-width-auto"
						       text
						       @click="action"
						>
							<v-icon>{{ icon }}</v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
			<div v-else class="text-center">
				{{ t('driverInfo.NoAvailableCars') }}
			</div>
		</template>

		<Modal :is-active="state.isPopupAddVehicle"
		       :max-width="600"
		       @cancel="closeAddVehiclePopup"
		>
			<v-form ref="formRef" v-model="vehicleFormAdd" class="pa-6">
				<h2 class="section__title mb-2">
					{{ t('driverDetails.addVehicle') }}
				</h2>
				<VehicleInformation
					:disabled-info-text="false"
					:vehicle="state.addVehicleFields"
					@setCarFields="setCarFields"
				/>
				<div class="d-flex justify-end my-4">
					<Btn :disabled="!!isLoading"
					     :loading="!!isLoading"
					     class="mr-2"
					     @click="closeAddVehiclePopup"
					>
						{{ t('addVehicle.cancel') }}
					</Btn>
					<Btn :disabled="!isAddVehicleSaveEnabled || vehicleFormAdd === false || !!isLoading"
					     :loading="!!isLoading"
					     @click="saveCarsData"
					>
						{{ t('addVehicle.save') }}
					</Btn>
				</div>
			</v-form>
		</Modal>
		<Modal
			:is-active="state.isPopupEditVehicle"
			:max-width="600"
			@cancel="closeEditPopup"
		>
			<v-form ref="formRef" v-model="vehicleFormEdit" class="pa-6">
				<h2 class="section__title mb-2">
					{{ t('driverDetails.EditVehicle') }}
				</h2>
				<VehicleInformation
					:disabled-info-text="false"
					:vehicle="state.editVehicleFields"
					@setCarFields="setEditCarFields"
				/>
				<div class="d-flex justify-end my-4">
					<Btn :disabled="!!isLoading"
					     :loading="!!isLoading"
					     class="mr-2"
					     @click="closeEditPopup"
					>
						{{ t('addVehicle.cancel') }}
					</Btn>
					<Btn
						:disabled="!isEditVehicleSaveEnabled || vehicleFormEdit === false || !!isLoading"
						:loading="!!isLoading"
						@click="confirmEdit"
					>
						{{ t('addVehicle.save') }}
					</Btn>
				</div>
			</v-form>
		</Modal>
		<Popup :active="state.isPopupAskDeleteVehicle"
		       :loading="store.getters['isLoading']"
		       :popup-data="state.popupData.question"
		       @accept="deleteVehicle"
		       @cancel="state.isPopupAskDeleteVehicle = false"
		/>
	</div>
</template>

<script setup>
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { onMounted, reactive, computed, ref } from 'vue'

// components
import Btn from '@/components/inputs/Btn.vue'
import Modal from '@/components/modals/Modal.vue'

// utils
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValidPhone,
    isValidEmail,
    maxCharsExceeded
} = useValidations()

// constants 
const SMS_SENT_OK = 'SMS_SENT_OK'
const SMS_ALREADY_SENT_NOT_EXPIRED_YET = 'SMS_ALREADY_SENT_NOT_EXPIRED_YET'
const PHONE_ALREADY_VALIDATED = 'PHONE_ALREADY_VALIDATED'

const phone = ref()
const form = ref()
const { commit, dispatch } = store
const emit = defineEmits(['setError'])
const { t } = useI18n()

const state = reactive({
    isEmailVerified: false,
    isEmailVerificationChecked: false,
    isPhoneVerified: false,
    isSkipVerificationPhone: false,
    isPhoneVerificationModalVisible: false,
    isPhoneSkipVerificationModalVisible: false,
    errors: '',
    showSendAgainButton: false,
    recaptchaSiteKey: import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null,
    code: '',
    errorMessage: '',
    isFormValid: false,
    isCodeChecking: false
})

const account = computed(() => store.state.account)
const accountTemp = computed(() => store.state.settings.accountTemp)
const isEditView = computed(() => {
    return store.state.account.personalDataIsEditView
})

const changePhone = (value) => {
    // reset to enable verification for new phone number
    if (isPhoneValid.value && accountTemp.value?.phone !== value) {
        state.isPhoneVerified = false
    }

    if (isPhoneValid) {
        updateAccountTemp(value, 'phone')
    }

    if (state.isPhoneVerified && !state.isSkipVerificationPhone) {
        state.isPhoneVerified = false
    }
}

const setIsPhoneVerified = () => {
    state.isPhoneVerified = true
    state.errors = ''
}


const skipVerificationPhone = () => {
    state.isPhoneVerificationModalVisible = false
    state.isPhoneSkipVerificationModalVisible = true
    state.isSkipVerificationPhone = true
    setIsPhoneVerified()
}

const checkIsPhoneVerified = () => {
    const data = isEditView.value ? accountTemp.value : account.value
    if (data.roles.includes('payer')) {
        apiRequest('client/phone-verified').then(response => {
            state.isPhoneVerified = response.data
            state.isSkipVerificationPhone = false
        }).catch((error) => {
            typeof error.msg !== 'undefined' ? showMessage(error.msg) : showMessage(error.message)
        })
    }
}

const isBtnVerifyPhone = computed(() => {
    if (accountTemp.value) {
        return isValidPhone(accountTemp.value?.phone) === true && !state.isPhoneVerified
    }
    return false
})

const isPhoneValid = computed(async () => {
    const { valid } = await phone.value?.validate()
    return valid
})

const resendVerificationEmail = () => {
    apiRequest.post('client/resend-verification-email').catch(error => {
        showMessage(error)
    })
    showMessage(t('Client.VerificationEmailSent'), 'success')
}

const checkIsEmailVerified = () => {
    const data = isEditView.value ? accountTemp.value : account.value
    if (data.roles.includes('payer')) {
        apiRequest('client/email-verified').then(response => {
            state.isEmailVerified = response?.data
        }).catch(error => {
            showMessage(error)
        }).finally(() => {
            state.isEmailVerificationChecked = true
        })
    }
}

const updateAccountTemp = (value, field) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}

const cancel = () => {
    state.isPhoneVerificationModalVisible = false
    state.errorMessage = ''
    state.code = ''
}

const sendVerificationCode = async () => {
    grecaptcha.enterprise.ready(async () => {
        const recaptchaToken = await grecaptcha.enterprise.execute(state.recaptchaSiteKey, { action: 'sms_send_verification' })
        try {
            await dispatch('news/getNotifications')
            const { data } = await apiRequest.post('sms/send-verification', {
                phone: accountTemp.value.phone,
                'g-recaptcha-response': recaptchaToken
            })
	        
            switch (data.status) {
                case SMS_SENT_OK:
                case SMS_ALREADY_SENT_NOT_EXPIRED_YET:
                    showMessage(data.message, 'success')
                    state.isPhoneVerificationModalVisible = true
                    break
                case PHONE_ALREADY_VALIDATED:
                    showMessage(t('Client.PhoneIsAlreadyVerified'), 'success')
                    setIsPhoneVerified()
                    cancel()
                    break
            }
        } catch (error) {
	        if(typeof error.errors !== 'undefined') {
		        Object.keys(error.errors).forEach(key => {
					let message = error.errors[key].join('\n')
			        showMessage(message)
		        })
	        } else {
		        typeof error.msg !== 'undefined' ? showMessage(error.msg) : showMessage(error.message)
	        }
			
         
        } finally {
            state.showSendAgainButton = true
        }
    })
}
const verifyCode = async () => {
    const { valid } = await form.value.validate()
    state.isFormValid = valid

    if (!state.isFormValid) {
        return
    }

    state.isCodeChecking = true
    apiRequest.post('sms/verify', { code: state.code, phone: accountTemp.value.phone })
        .then(({ data }) => {
            if (data.status === 'error' || data.status === 'errorTimeout') {
                state.errorMessage = data.message
            } else {
                setIsPhoneVerified()
                cancel()
            }
            dispatch('news/getNotifications')
        }).catch(error => {
            if (typeof error === 'string') {
                state.errorMessage = error
            } else {
                state.errorMessage = error.message
            }
            showMessage(state.errorMessage)
        }).finally(() => {
            state.isCodeChecking = false
        })
}

onMounted(() => {
    checkIsPhoneVerified()
    checkIsEmailVerified()
})
</script>

<template>
    <v-row class="phone-email-component">
        <v-col
            cols="12"
            md="6"
        >
            <v-text-field
                ref="phone"
                density="compact"
                variant="outlined"
                :disabled="!isEditView"
                :success="state.isSkipVerificationPhone ? false : state.isPhoneVerified"
                :error-messages="state.errors"
                :rules="[isNotEmpty, isValidPhone]"
                :model-value="isEditView ? accountTemp.phone : account.phone"
                :label="t('Client.Phone') + '*'"
                :placeholder="t('Client.Phone') + '*'"
                type="tel"
                class="mobile-phone"
                data-testid="phone"
                @click="emit('setError', { key: 'phone', value: '' })"
                @update:model-value="changePhone($event)"
            >
                <template v-slot:append>
                    <v-btn
                        v-if="isBtnVerifyPhone"
                        v-ripple
                        elevation="0"
                        color="primary"
                        class="verify-icon"
                        :disabled="!isPhoneValid"
                        @click="sendVerificationCode"
                    >
                        {{ t('Client.SaveAndVerify') }}
                    </v-btn>
                </template>
            </v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
            <Modal
                v-if="state.isPhoneVerificationModalVisible"
                :is-active="state.isPhoneVerificationModalVisible"
                :max-width="450"
                @cancel="cancel"
                @keydown.esc="cancel()"
                class="phone-verification-wrapper"
            >
                <v-form
                    ref="form"
                    v-model="state.isFormValid"
                    class="form"
                >
                    <v-img src="assets/icons/phoneVerification.svg" />
                    <h2 class="mb-8 mt-8">
                        {{ t('PhoneVerification.VerifyPhoneNumber') }}
                    </h2>
                    <p class="align-center mb-2">
                        {{ t('PhoneVerification.SentTheCode') }}
                    </p>
                    <v-btn
                        v-if="state.showSendAgainButton"
                        text
                        plain
                        color="primary"
                        class="mb-2"
                        @click="sendVerificationCode"
                    >
                        {{ t('PhoneVerification.SendAgain') }}
                    </v-btn>
                    <v-container fluid>
                        <v-row>
                            <v-col md="12">
                                <v-text-field
                                    v-model="state.code"
                                    variant="outlined"
                                    :error-messages="state.errorMessage"
                                    :label="t('PhoneVerification.EnterCode')"
                                    :placeholder="t('PhoneVerification.EnterCode')"
                                    type="tel"
                                    @click="state.errorMessage = ''"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="d-flex justify-end ml-auto">
                        <Btn
                            class="mr-4"
                            text
                            @click="skipVerificationPhone()"
                        >
                            {{ t('PhoneVerification.Skip') }}
                        </Btn>
                        <Btn
                            :loading="state.isCodeChecking"
                            :disabled="!state.code.trim().length"
                            @click="verifyCode"
                        >
                            {{ t('PhoneVerification.Verify') }}
                        </Btn>
                    </div>
                </v-form>
            </Modal>
            <v-row
                v-else
                class="info-box px-3 py-2 d-flex align-center sms ma-2"
            >
                <v-col cols="2">
                    <v-icon icon="mdi-exclamation-thick" size="x-large" color="primary"/>
                </v-col>
                <v-col cols="10">
                    <p class="info-text">
                        {{ t('driverInfo.smsInfo') }}
                    </p>
                </v-col>
            </v-row>

            <Modal
                :is-active="state.isPhoneSkipVerificationModalVisible"
                :max-width="450"
                @cancel="state.isPhoneSkipVerificationModalVisible = false"
            >
                <div class="pa-5">
                    <p>{{ t('PhoneVerification.PleaseVerifyMobile') }}</p>
                    <p>{{ t('PhoneVerification.CustomerServiceHotline') }}:</p>
                    <p>{{ t(`phoneNumber.customerServiceOnline`) }}</p>
                    <div class="d-flex justify-end">
                        <Btn @click="state.isPhoneSkipVerificationModalVisible = false">
                            {{ t('PhoneVerification.Ok') }}
                        </Btn>
                    </div>
                </div>
            </Modal>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
            <v-text-field
                density="compact"
                variant="outlined"
                type="email"
                :disabled="!isEditView"
                :label="t('Client.Email') + '*'"
                :placeholder="t('Client.Email') + '*'"
                :model-value="isEditView ? accountTemp.email : account.email"
                :rules="[isNotEmpty, isValidEmail, maxCharsExceeded(100)]"
                :hide-details="true"
                :class="{ 'mb-5': !state.isEmailVerificationChecked }"
                data-testid="email"
                @update:model-value="updateAccountTemp($event, 'email')"
            />
        </v-col>
        <v-col
            cols="12"
            md="6"
            class="d-flex align-center"
        >
            <template v-if="state.isEmailVerificationChecked">
                <v-btn
                    v-if="!state.isEmailVerified"
                    v-ripple
                    class="verify-icon"
                    block
                    size="large"
                    elevation="0"
                    color="primary"
                    @click="resendVerificationEmail()"
                >
                    <span>
                        {{ t('Client.ResendVerificationEmail') }}
                    </span>
                </v-btn>
                <v-row
                    v-else
                    class="info-box px-3 py-2 d-flex align-center ma-2"
                >
                    <v-col cols="2">
                        <v-icon icon="mdi-exclamation-thick" size="x-large" color="primary"/>
                    </v-col>
                    <v-col cols="10">
                        <p class="info-text">
                            {{ t('driverInfo.emailInfo') }}
                        </p>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
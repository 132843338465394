<script setup>
import { useStore } from 'vuex'
import {computed, onMounted, ref, watch} from 'vue'

// Translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
const store = useStore()

const queue = ref([])

const TIME = 5000

const removeSnackbar = (index) => {
	queue.value.splice(index, 1)
	recalculatePositions()
}

const recalculatePositions = () => {
	queue.value = queue.value.map((snackbar, i) => ({
		...snackbar,
		top: `${70 * i}px`
	}))
}

const queueStringify = computed(() => {
	return JSON.stringify(queue.value)
})

watch(queueStringify,  () => {
	queue.value = queue.value.filter(el => el !== false)
})

onMounted(() => {
	store.subscribe((mutation, state) => {
		if (mutation.type === 'setNotify') {
			const newMessage = state.notify.content
			const exists = queue.value.some(el => el.message === newMessage)
			
			if (!exists) {
				queue.value.push({
					message: newMessage,
					color: state.notify.color || 'info',
					timeout: state.notify.timeout || TIME,
					location: 'top',
					position: 'fixed',
					top: `${70 * queue.value.length}px`
				})
			}
		}
	})
})
</script>

<template>
	<div>
		<v-snackbar
			v-for="(snackbar, index) in queue"
			:key="index"
			v-model="queue[index]"
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			:location="snackbar.location"
			:style="{ top: snackbar.top, position: 'fixed' }"
			@close="removeSnackbar(index)"
		>
			<div class="d-flex justify-space-between align-center">
				<template v-if="typeof snackbar.message === 'string'">
					{{ t(snackbar.message) }}
				</template>
				<template v-else>
					<div v-html="snackbar.message?.outerHTML" />
				</template>
				<v-btn class="mb-auto text-white" variant="plain" text @click="removeSnackbar(index)">
					<v-icon class="active-icon">mdi-close</v-icon>
				</v-btn>
			</div>
		</v-snackbar>
	</div>
</template>

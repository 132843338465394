
import { useI18n } from 'vue-i18n'

export default function useValidations() {

    const { t } = useI18n()

    const isNotEmpty = v => !!v || t('error.FieldIsRequired')
    const isValidEmail = v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v ? v.toLowerCase() : '') || t('error.InvalidEmail')
    const isValidPassword = v => v === '' || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(v) || t('error.MinimumSymbols')
    const isLongPassword = v => v.length < 45 || t('error.PasswordTooLong')
    const isLongPlateNumber = v => v ? v.length <= 20 || t('error.LongPlateNumber') : true
    const isValidPhone = v => /(^[+](421|48)[1-9][0-9]{8}$)|(^[+](?!(421|48))[0-9]{6,16}$)/.test(v) || t('error.OnlyDigits')
    const isValidYear = v => !v || /[0-9]{4}/.test(v) && v > 1890 && v <= 2050 || t('error.OnlyYear')
    const isValid1Character = v => !v || /^.*\S.*$/.test(v.replace(/[\n\r]/gs, '')) || t('error.AtLeast1Character')
    const isValid2Characters = v => !v || /^.*\S.*\S.*$/.test(v.replace(/[\n\r]/gs, '')) || t('error.AtLeast2Characters')
    const isValid3Characters = v => !v || /^.{3,}$/.test(v) || t('error.AtLeast3Characters')
    const isValidSlovakVat = v => !v || /^SK\d{10}$/i.test(v) || t('error.IncorrectVatId')
    const isValidPolishVat = v => !v || /^PL\d{10}$/i.test(v) || t('error.IncorrectVatId')
    const isValidCroatianVat = v => !v || /^HR\d{11}$/i.test(v) || t('error.IncorrectVatId')
    const isValidSlovakTax = v => !v || /^\d{10}$/.test(v) || t('error.IncorrectTaxId')
    const isValidCroatianTax = v => !v || /^\d{9}$/.test(v) || t('error.IncorrectTaxId')
    const isValidSlovakCompany = v => !v || /^(\d{6}|\d{8})$/.test(v) || t('error.IncorrectCompanyId')
    const isValidCroatianCompany = v => !v || /^\d{11}$/.test(v) || t('error.IncorrectCompanyId')
    const isValidPolishCompany = v => !v || /^\d{10}$/.test(v) || t('error.IncorrectPolishCompanyId')
    const isValidCardId = v => !v || /^\d{9}$/.test(v) || t('error.incorrectcardid')
    const maxCharsExceeded = (limit) => {
        return v => (v || '').length <= limit || t('error.MaxCharsExceeded')
    }
    const isMinValue = (limit) => {
        return v => !v || v >= limit || t('error.InvalidValue')
    }
    const isMaxValue = (limit, message) => {
        return v => !v || v <= limit || message
    }

    return {
        isMinValue,
        isMaxValue,
        isNotEmpty,
        isValidEmail,
        isValidPassword,
        isLongPassword,
        isLongPlateNumber,
        isValidPhone,
        isValidYear,
        isValid1Character,
        isValid2Characters,
        isValid3Characters,
        isValidSlovakVat,
        isValidPolishVat,
        isValidCroatianVat,
        isValidSlovakTax,
        isValidCroatianTax,
        isValidSlovakCompany,
        isValidPolishCompany,
        isValidCroatianCompany,
        isValidCardId,
        maxCharsExceeded
    }
}